import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import SEO from "../components/seo";

import Container from '../components/UI/Container';
import TeamMemberCard from '../components/TeamMemberCard';

const StyledWhiteBackground = styled.div`
  background: ${props => props.theme.colors.white};
  min-width: 1000px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -2;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    min-width: 600px;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: none;
  }
`

const TeamPage = () => {

  const data = useStaticQuery(graphql`
    query TeamEn {
      allTeamJson {
        edges {
          node {
            emanuel {
              en {
                name
                role1
                role2
                description1
                description2
                description3
                portfolio
              }
            }
            mayckon {
              en {
                name
                role1
                role2
                description1
                description2
                description3
                portfolio
              }
            }
          }
        }
  }
    }
  `)
  console.log(data)

  return (
    <>
      <SEO title="Team" />
      <Container>
        <TeamMemberCard
          id="emanuel"
          reverse={false}
          key={1}
          photo="/images/team/mayckon.webp"
          name={data.allTeamJson.edges[0].node.emanuel.en.name}
          role1={data.allTeamJson.edges[0].node.emanuel.en.role1}
          role2={data.allTeamJson.edges[0].node.emanuel.en.role2}
          description1={data.allTeamJson.edges[0].node.emanuel.en.description1}
          description2={data.allTeamJson.edges[0].node.emanuel.en.description2}
          description3={data.allTeamJson.edges[0].node.emanuel.en.description3}
          portfolio={data.allTeamJson.edges[0].node.emanuel.en.portfolio}
        />
        <TeamMemberCard
          id="mayckon"
          key={0}
          reverse={true}
          photo="/images/team/mayckon.webp"
          name={data.allTeamJson.edges[0].node.mayckon.en.name}
          role1={data.allTeamJson.edges[0].node.mayckon.en.role1}
          role2={data.allTeamJson.edges[0].node.mayckon.en.role2}
          description1={data.allTeamJson.edges[0].node.mayckon.en.description1}
          description2={data.allTeamJson.edges[0].node.mayckon.en.description2}
          description3={data.allTeamJson.edges[0].node.mayckon.en.description3}
          portfolio={data.allTeamJson.edges[0].node.mayckon.en.portfolio}
        />
        <StyledWhiteBackground />
      </Container>
    </>
  );
}

export default TeamPage;
